.breakdown-card {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: space-between;
    /* todo: vars */
    gap: 1rem;
    padding: 0.5rem 1rem;
    min-width: 10rem;
    max-width: 17rem;
    background-color: rgba(0, 0, 0, 0.75);
    color: white;
    border-radius: 1rem;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
}

.breakdown-card__icon {
    flex: 1 1 auto;
}

.breakdown-card__name {
    font-family: 'Nunito';
    font-weight: 900;
    flex: 3 1 auto;
    text-align: left;
}

