.certificate {
    background-image: url("../../assets/img/certificate_bg.jpg"), linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5));
    background-blend-mode: overlay;
    background-position: center;
    background-repeat: no-repeat;
    color: white;
    padding: 1rem;
    background-size: cover;
}

.certificate--container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 1rem;
}

.certificate--border {
    border: 1px solid white;
}

.certificate--title {
    font-weight: 900;
    font-size: 3rem;
    text-align: center;
}

.certificate--subtitle {
    font-size: 1.5rem;
    text-align: center;
}

.certificate--weight {
    font-weight: 700;
    font-size: 5rem;
    margin-block: unset;
    text-align: center;
}

.certificate--description {
    text-align: center;
}

.certificate--display-name {
    font-weight: 700;
    font-size: 3rem;
    text-align: center;
}

.footer-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.certificate--footer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.certificate--footer--date {
    text-align: left;
}

.certificate--footer--id {
    text-align: right;
}