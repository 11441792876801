:root {
    --featured-radius: 30px;
}

.error-wrapper {
    margin: 0 auto;
}

@media (min-width: 576px) {
    .error-wrapper {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .error-wrapper {
        max-width: 720px;
    }
}

.img-wrapper {
    max-width: 65%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
}

.error-content-area {
    padding: 75px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 3px 80px 0px rgb(0 0 0 / 10%);
    border-radius: var(--featured-radius);
    padding: 120px;
    position: relative;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.7;
}